<template>
  <b-sidebar v-if="renderSidebar" position="static" v-model="sidebarVisible" fullheight static mobile="fullwidth" :reduce="reduced" :class="{ 'is-open': sidebarIsOpen }">
    <button class="toggle-button" href="#" @click="reduced = !reduced">
      <i class="mdi" :class="[reduced ? 'mdi-arrow-right' : 'mdi-arrow-left']"></i>
    </button>

    <div class="sidebar-container">
      <div class="menu-wrapper">
        <div class="menu">
          <ul>
            <menu-set :key="menu.id" v-for="menu in homeMenuSet" :menu="menu"></menu-set>
            <menu-set :key="menu.id" v-for="menu in menuSets" :menu="menu"></menu-set>
            <template v-if="$store.state.currentUser.role.show_admin_menu">
              <menu-set :key="menu.id" v-for="menu in staticMenuSets" :menu="menu"></menu-set>
            </template>
          </ul>
        </div>
      </div>
      <div class="ld-footer">
        <img v-if="currentHospital && currentHospital.logo_url" src="@/assets/ld-logo-all-white.png" alt="">
        <ul class="ld-footer__links">
          <li><a target="_blank" href="https://lookdeep.health" rel="noopener">About</a></li>
          <li><a target="_blank" href="https://www.lookdeep.health/privacy-policy" rel="noopener">Privacy</a></li>
        </ul>
        <p class="ld-footer__copyright">LookDeep Inc.® All rights reserved</p>
        <p class="ld-footer__build" :title="currentHospital.id">{{ build }}</p>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
  import { mapState } from "vuex"
  import {EVENTS, EventBus} from "@/lib/EventBus";
  import MenuSet from "@/components/menu/MenuSet.vue";
  import MenuSetClass from "@/lib/menu/MenuSet";
  import MenuItemClass from "@/lib/menu/MenuItem";
  import MenuGroupClass from "@/lib/menu/MenuGroup";
  import to from "@/lib/to";
  import {
    buildStaticMenuSets,
    homeMenuSet,
    PAGE_CONFIG,
    PAGE_LINK,
    PAGE_PERMISSION,
    PAGE_TYPE
  } from "@/lib/menu/MenuConfig";
  export default {
    name: "Sidebar",
    components: {MenuSet},
    computed: {
      ...mapState(['sidebarIsOpen', 'currentHospital']),
      ...mapState('UIStore', ['renderSidebar'])
    },
    data() {
      return {
        reduced: false,
        sidebarVisible: true,
        build: process.env.VUE_APP_BUILD,
        menuSets: [],
        homeMenuSet,
        staticMenuSets: buildStaticMenuSets(this)
      }
    },
    methods: {
      addDefaultExpandedClass() {
        const menuSets = document.getElementsByClassName('menu-set')
        menuSets.forEach(menuSet => {
          if (menuSet.getAttribute('data-expanded-default') === 'true') {
            menuSet.classList.add('expanded', 'expanded-default')
            menuSet.querySelectorAll('.menu-set__group.has-children').forEach(el => el.classList.add('expanded', 'expanded-default'))
          }
        })
      },
      addExpandedClassToCurrent() {
        const rootItems = document.querySelectorAll('.b-sidebar li.expanded')
        rootItems.forEach(el => {
          if (el.querySelectorAll('.router-link-active').length == 0) {
            el.classList.remove('expanded')
          }
        })

        const { menu_id, item_id } = this.$route.query
        if (menu_id && item_id) {
          document.querySelectorAll('.menu-set .router-link-active').forEach(el => el.classList.remove('router-link-active'))
          document.querySelectorAll('.menu .expanded').forEach(el => el.classList.remove('expanded'))
          const menuItem = document.getElementById(`menu-item-${item_id}`)
          if (menuItem) {
            [menuItem.closest('.menu-set.has-children'), menuItem.closest('.menu-set__group.has-children')].forEach(expandable => {
              if (expandable) {
                expandable.classList.add('expanded')
              }
            })
            menuItem.classList.add('router-link-active')
          }
        } else {
          const menuItem = document.getElementsByClassName('router-link-active')[0]
          if (menuItem) {
            document.querySelectorAll('.router-link-active[data-id]').forEach(el => el.classList.remove('router-link-active'));
            [menuItem.closest('.menu-set.has-children'), menuItem.closest('.menu-set__group.has-children')].forEach(expandable => {
              if (expandable) {
                expandable.classList.add('expanded')
              }
            })
          }
        }
      }
    },
    async mounted() {
      document.getElementsByClassName('menu')[0].addEventListener('click', e => {
        // if navigation link is clicked
        if (e.target.closest('.menu-set__router-link')) {
          return;
        }

        const children = e.target.closest('.has-children')
        if (children) {
          children.classList.toggle('expanded')
          e.preventDefault()
        }

      });
      EventBus.$on(EVENTS.SIDEBAR_OPEN_STATE, state => {
        this.sidebarVisible = state
      });
      EventBus.$on(EVENTS.SIDEBAR_REDUCED_STATE, state => {
        this.reduced = state
      });
      const [err, response] = await to(this.$http.get('/menu_sets'))
      if (!err) {
        const buildMenuItemClass = (menuSet, menuItem) => {
          const route = {...PAGE_LINK[menuItem.schema.page]}
          const pageConfig = PAGE_CONFIG[menuItem.schema.page]
          route.query = { menu_id: menuSet.id, item_id: menuItem.randomId }
          switch (pageConfig.pageType) {
            case PAGE_TYPE.TABLE:
              route.query = {...route.query, ...menuItem.schema.filters}
              break
            case PAGE_TYPE.CENTRAL_MONITORING:
              if (parseInt(menuItem.schema.viewId)) {
                route.params = {viewId: menuItem.schema.viewId}
              }
              break
          }
          const permissionFunc = PAGE_PERMISSION[menuItem.schema.page]
          const permissions = permissionFunc ? permissionFunc.call(this) : true
          return new MenuItemClass(menuItem.randomId, menuItem.schema.label, route, menuItem.schema.enabled, menuItem.schema.icon, permissions)
        }
        const { role } = this.$store.state.currentUser
        this.menuSets = response.data.map(menuSet => {
          const setClass = new MenuSetClass(menuSet.id, menuSet.title, menuSet.icon, menuSet.expanded)
          setClass.enabled = menuSet.enabled && (role.kind == 'lookdeep' || menuSet.roles.length == 0 || menuSet.roles.find(x => x.id == role.id))
          setClass.children = menuSet.menu_items.map(menuItem => {
            if (menuItem.schema.pageType === PAGE_TYPE.GROUP) {
              const allDisabled = menuItem.schema.menu_items.every(menuItem => !menuItem.schema.enabled)
              const groupEnabled = menuItem.schema.enabled && !allDisabled
              return new MenuGroupClass(menuItem.schema.label, menuItem.schema.icon || '', groupEnabled, menuItem.schema.menu_items.map(menuItem => buildMenuItemClass(menuSet, menuItem)))
            } else {
              return buildMenuItemClass(menuSet, menuItem)
            }
          });
          return setClass
        })
        this.$nextTick(() => {
          this.addExpandedClassToCurrent()
          this.addDefaultExpandedClass()
        })
      }
    },
    watch: {
      '$route': {
        handler() {
          this.$nextTick(() => {
            this.addExpandedClassToCurrent()
            this.addDefaultExpandedClass()
          })
        },
        immediate: true
      },
    }
  }
</script>


<style scoped lang="scss">
:deep(.sidebar-content) {
  box-shadow: 5px 0px 8px -5px rgb(230, 231, 236);
  background: #5048FF;
  position: relative !important;
  overflow: visible !important;
  .menu-set__popper {
    display: none;
  }
}
.b-sidebar:hover :deep(.toggle-button) {
  opacity: 1;
}

:deep {
  .menu {
    margin: 30px 15px 0 15px;
  }
}
.menu__divider:before {
  display: block;
  content: '';
  width: 100%;
  height: 1px;
  background: linear-gradient(270deg, #5048FF 5%, rgba(255, 255, 255, 0.5) 50%, #5048FF 95%);
  margin: 10px 0 10px;
}
.toggle-button {
  background: #ffffff;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  position: absolute;
  top: 5px;
  right: -13px;
  box-shadow: 0 0 3px 1px $main-shadow-color;
  cursor: pointer;
  outline: none !important;
  transition: opacity 0.1s linear;
  opacity: 0;
  border: 1px solid #cfcfcf;
}
@include until($desktop) {
  .b-sidebar {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 9999999999;
    display: none;
    &.is-open {
      display: block;

    }
  }
  .toggle-button {
    display: none;
  }
}
.ld-footer {
  flex: 0;
  padding: 15px 15px 25px 15px;
  text-align: center;
  background: #2e42a3;
  img {
    width: 150px;
    margin-bottom: 5px;
  }
}
.sidebar-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.menu-wrapper {
  flex: 1 1;
  overflow: hidden;
  overflow-y: auto;
}
.ld-footer__copyright {
  font-size: 12px;
  color: #5972e0;
}
.ld-footer__build {
  font-size: 12px;
  color: #5972e0;
  padding-top: 6px;
}
.ld-footer__links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3px 0 8px;
}
.ld-footer__links li {
  padding-right: 10px;
}
.ld-footer__links li a {
  font-size: 12px;
  color: #d2d2d2;
  transition: color 0.1s linear;
  &:hover {
    color: #ffffff;
  }
}
:deep {
  .is-mini {
    .has-padding {
      padding-left: 10px !important;
    }
    .menu-set__group.expanded {
      background: #4971ffa1;
      border-radius: 10px;
      box-shadow: 0 0 7px -2px #2e42a3;
      > div > .menu-set__link {
        background: #2e42a3;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid #ffffff54;
      }
      ul .menu-set__link.router-link-active, ul .menu-set__link:hover {
        border-radius: 0;
      }
      ul li:last-child {
        .menu-set__link:hover,
        .menu-set__link.router-link-active {
          border-radius: 0 0 10px 10px !important;
        }
      }
    }
    .section-divider__link i {
      display: block;
    }
    .menu-set__popper {
      display: block;
    }
    .ld-footer {
      display: none;
    }
    li a span {
      display: none;
    }
    .menu-wrapper {
      //overflow: visible;
    }
  }
}
</style>
