/**
 * Filter Types
 */
const FilterType = Object.freeze({
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  ENUM: 'ENUM',
  DATERANGE: 'DATERANGE',
  DATETIMERANGE: 'DATETIMERANGE',
  DATE: 'DATE',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
})

/**
 * @property {string} name - name used as a key in objects and in URL
 * @property {string} displayName - name displayed in UI
 * @property {FilterType} type - filter type
 * @property {boolean} unique - flag to make filter unique. i.e only one of it's kind can exists
 * @property {boolean} enumValues - Values for filer with type of ENUM
 * @property {string} apiEndpoint - API endpoint for autocomplete
 */
class TableFilter {
  constructor({ name, displayName, type, enumValues, isInline, apiEndpoint }) {
    this.name = name
    this.displayName = displayName
    this.isInline = isInline || false
    if (type) {
      if (Object.prototype.hasOwnProperty.call(FilterType, type)) {
        this.type = FilterType[type]
      } else {
        throw new Error('Wrong filter type')
      }
    }
    if (type === FilterType.ENUM) {
      this.enumValues = enumValues
    }
    if (type === FilterType.AUTOCOMPLETE) {
      this.apiEndpoint = apiEndpoint
    }
  }
}


export {TableFilter, FilterType }
